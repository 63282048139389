<template>
<!--  每日清单-->
  <div>
    <div class="yyqr-uni-list">
      <van-cell-group>
        <van-cell
            title="切换就诊人"
            is-link
            style="text-align: left"
            @click="openChooise()"
        />
      </van-cell-group>
    </div>
    <van-popup v-model="showPopup" position="bottom" closeable>
      <div class="yyqr-chooise-jzk-over">
        <div class="yyqr-chooise-title">选择就诊人</div>
      </div>
      <!-- <div class="yyqr-chooise-jzk" v-for="(item,index) in list" :key="index">黄爽(420xxx20001029451x)</div> -->
      <div class="yyqr-chooise-jzk" v-show="!isEmpty">
        黄爽(420xxx20001029451x)
      </div>
      <div class="yyqr-chooise-nodata" v-show="isEmpty">
        暂无就诊人信息,请添加
      </div>
      <div class="yyqr-chooise-add">
        <van-icon name="plus"/>
        添加就诊人
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  methods: {
    // 点击就诊人
    openChooise() {
      this.showPopup = true;
    },
  }
}
</script>

<style scoped>

</style>